export const sentenceCase = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};

export const format = (str: string, ...args: string[]) => {
  if (!str) {
    return '';
  }

  return str.replaceAll(/{(\d+)}/g, (match, idx) => {
    return args[idx] === undefined ? '' : args[idx];
  });
};

export const pluralize = (count: number, singular: string, plural: string) => {
  return count === 1 ? singular : plural;
};

export const parseName = (name: string, emptyValue = '') => {
  const cleanedName = name.trim();
  const lastSpaceIdx = cleanedName.lastIndexOf(' ');

  if (lastSpaceIdx < 0) {
    return {
      firstName: cleanedName,
      lastName: emptyValue,
    };
  }

  const firstName = cleanedName.slice(0, Math.max(0, lastSpaceIdx));
  const lastName = cleanedName.slice(Math.max(0, lastSpaceIdx + 1));

  return {
    firstName,
    lastName,
  };
};

export const getNameParts = ({
  firstName,
  fullName,
  lastName,
}: {
  firstName?: string;
  fullName?: string;
  lastName?: string;
}) => {
  let resolvedFirstName = firstName;
  let resolvedLastName = lastName;

  if (fullName && (!resolvedFirstName || !resolvedLastName)) {
    const parsedName = parseName(fullName);

    resolvedFirstName = resolvedFirstName || parsedName.firstName;
    resolvedLastName = resolvedLastName || parsedName.lastName;
  }

  const resolvedFullName = [resolvedFirstName, resolvedLastName].filter(Boolean).join(' ');

  return {
    firstName: resolvedFirstName,
    fullName: resolvedFullName,
    lastName: resolvedLastName,
  };
};
