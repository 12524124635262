globalThis["__sentryRewritesTunnelPath__"] = undefined;
globalThis["SENTRY_RELEASE"] = {"id":"dec15fd57c17809d31b0c3cd14a0dacbf093d821"};
globalThis["__sentryBasePath"] = undefined;
globalThis["__rewriteFramesAssetPrefixPath__"] = "";
// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/manual-setup/#create-initialization-config-files

import * as Sentry from '@sentry/nextjs';

import sentryBaseConfig from './sentry.base.config';

if (process.env.NEXT_PUBLIC_ENABLE_SENTRY === 'true') {
  Sentry.init(sentryBaseConfig);
}
