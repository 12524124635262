import React, { createContext, ReactNode } from 'react';

import Cookies from 'universal-cookie';

import useQueryParam from '../hooks/useQueryParam';

interface UserContextValue {
  rtlid: string | string[];

  // @TODO - to remove - https://github.com/eslint-stylistic/eslint-stylistic/issues/459
  _eslintIgnore_?: never;
}

const UserContext = createContext<undefined | UserContextValue>(undefined);

interface UserProviderProps {
  children: ReactNode;

  // @TODO - to remove - https://github.com/eslint-stylistic/eslint-stylistic/issues/459
  _eslintIgnore_?: never;
}

const validateRtlid = (id: null | string) => {
  return typeof id === 'string' && id.length === 18 && (id.startsWith('003') || id.startsWith('00Q'));
};

const cookies = new Cookies();

const getRtlid = (paramValue: string, cookieValue: string) => {
  if (validateRtlid(paramValue)) {
    return paramValue;
  }

  if (validateRtlid(cookieValue)) {
    return cookieValue;
  }

  return null;
};

const storeToCookie = (rtlid: string) => {
  if (typeof window !== 'undefined') {
    const oneYearFromNow = new Date();
    oneYearFromNow.setFullYear(oneYearFromNow.getFullYear() + 1);

    cookies.set('rtlid', rtlid, {
      domain: window.location.hostname,
      expires: oneYearFromNow,
      path: '/',
    });
  }
};

export const UserProvider: React.FC<UserProviderProps> = ({ children }) => {
  const [paramValue] = useQueryParam('rtlid');
  const rtlidFromCookie = cookies.get('rtlid');

  const rtlid = getRtlid(paramValue?.toString(), rtlidFromCookie?.toString());

  if (rtlid !== rtlidFromCookie) {
    storeToCookie(rtlid);
  }

  return (
    <UserContext.Provider value={{ rtlid }}>
      {children}
    </UserContext.Provider>
  );
};

export default UserContext;
