import Image from '../components/basic/Image';
import { checkIsDevelopment } from '../lib/_helpers';
import { setGlobalOptions } from '../lib/globals';

const FLAGS = {
  AU: 'https://rippling2.imgix.net/flag-Australia.svg',
  CA: 'https://rippling2.imgix.net/flag-Canada.svg',
  DE: 'https://rippling2.imgix.net/CountryDE-Germany.svg',
  ES: 'https://rippling2.imgix.net/CountryES-Spain.svg',
  FR: 'https://rippling2.imgix.net/flag-France.svg',
  GB: 'https://rippling2.imgix.net/flag-UK.svg',
  IE: 'https://rippling2.imgix.net/flag-Ireland.svg',
  IN: 'https://rippling.imgix.net/images/india-flag.svg',
  IT: 'https://rippling2.imgix.net/CountryIT-Italy.svg',
  NL: 'https://rippling2.imgix.net/CountryNL-Netherlands.svg',
  PT: 'https://rippling2.imgix.net/CountryPT-Portugal.svg',
  US: 'https://rippling2.imgix.net/flag-USA.svg',
};

export const getFlag = (country, additionalClasses) => (
  <Image
    attributes={{
      className: `${additionalClasses || ''}`,
      mediaURL: FLAGS[country],
      nonResponsive: true,
    }}
  />
);

export const getFlagXS = (country) => (
  <Image
    attributes={{
      className: '',
      mediaURL: FLAGS[country],
      nonResponsive: true,
    }}
  />
);

export const getCachedGlobals = (type, globals) => {
  const {
    footer, head, header,
  } = setGlobalOptions({
    data: { globals },
    slug: '',
    type,
  });

  return {
    ...globals,
    footer,
    head: {
      ...head,
      mode: { isDevelopment: checkIsDevelopment() },
    },
    header,
  };
};
